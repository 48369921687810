import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MessageService {
  isVisible$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  message$: Subject<string> = new Subject();

  constructor() { }

  toggleIsVisible() {
    this.isVisible$.next(
      !this.isVisible$.getValue()
    )
  }

  setMessage(text: string) {
    this.message$.next(text);
  }

  show() {
    this.isVisible$.next(true)
  }

  hide() {
    this.isVisible$.next(false)
  }

  reset(){
    this.hide();
    this.setMessage(null);
  }
}
