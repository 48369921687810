import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Session } from 'src/app/entities/session';
import { JwtHelperService } from "@auth0/angular-jwt";

import { SESSION_CONFIG } from 'src/config/session.config';

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  private jwtHelperService = new JwtHelperService();

  constructor(
  ) { }

  private session: Session = null;
  private session$: BehaviorSubject<Session> = new BehaviorSubject(this.session);

  private user: Session = null;
  private user$: BehaviorSubject<any> = new BehaviorSubject(this.user);

  getSession(): BehaviorSubject<Session> {
    return this.session$;
  }

  getUser(): BehaviorSubject<Session> {
    return this.user$;
  }

  setUser(user) {
    this.user = user;
    this.user$.next(user);
  }

  setSession(value): boolean {
    sessionStorage.setItem(SESSION_CONFIG.session_name, JSON.stringify(value));
    if(!this.getSession()) return;

    this.session = value;
    this.session$.next(value);

    return true;

  }

  isSessionValid(): boolean {
    if(!this.getSession().value) return;

    const expires_at: Date = new Date(this.session.expires_at);
    return (expires_at.getTime() > Date.now()) ? true : false;
  }

  getToken(): string {
    const session: Session = this.getSession().value;
    return session ? session.access_token : '';
  }

  getUserId(): number {
    const decodedToken = this.jwtHelperService.decodeToken(this.session.access_token);
    return parseInt(decodedToken.sub);
  }

  getSessionFromStorage() {
    const session: Session = JSON.parse(sessionStorage.getItem(SESSION_CONFIG.session_name)) || null;
    this.session = session;
    this.session$.next(session);
  }
}
