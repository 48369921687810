import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(
    private http: HttpClient
  ) { }

  public login(params) {
    return this.http.post(`${environment.backendHost}/auth/login`, {
      email: params.email,
      password: params.password
    });
  }
}
