export const GLOBAL_CONFIG = {
  breakpoints: [700, 900, 1300, 1500]
};

export const CELL_TYPES = {
  question: 'question',
  default: 'default',
  electricity: 'electricity',
  pharmacy: 'pharmacy',
  clean_point: 'clean_point',
  workshop: 'workshop',
  glass: 'glass',
  plastic: 'plastic',
  paperboard: 'paperboard',
  organic: 'organic',
  watchmaking: 'watchmaking',
  school: 'school',
  trash: 'trash',
  park: 'park',
  supermarket: 'supermarket',
  city_hall: 'city_hall',
  challenge: 'challenge',
  bicycle: 'bicycle',
};

export const DEPOSIT_CELLS = [
  CELL_TYPES['pharmacy'],
  CELL_TYPES['glass'],
  CELL_TYPES['plastic'],
  CELL_TYPES['organic'],
  CELL_TYPES['clean_point'],
  CELL_TYPES['electricity'],
  CELL_TYPES['watchmaking'],
  CELL_TYPES['school'],
  CELL_TYPES['paperboard'],
];

export const GET_CHIP_CELLS = [
  CELL_TYPES['park'],
  CELL_TYPES['city_hall'],
  CELL_TYPES['supermarket'],
  CELL_TYPES['workshop'],
];

export const CHALLENGE_TYPES = {
  penalisation: "penalisation",
  soupWords: "soup",
  hangedWords: "hanged",
  relatedItems: "relation",
  differences:"differences"
}

