import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class ApiService {
  constructor(private http: HttpClient) {}

  public getGameStatus(params) {
    return this.http.get(`${environment.backendHost}/gameStatus/get`, {
      params,
    });
  }

  public getCurrentGame(params: { playerId }) {
    return this.http.get(`${environment.backendHost}/player/gameStatus`, {
      params,
    });
  }

  public getUsers(params): Observable<any> {
    return this.http.get(`${environment.backendHost}/users/get`, {
      params,
    });
  }

  public getCreatedGames(params): Observable<any> {
    return this.http.get(`${environment.backendHost}/users/created-games`, {
      params,
    });
  }

  public getUserGroups(params): Observable<any> {
    return this.http.get(`${environment.backendHost}/users/groups`, {
      params,
    });
  }

  public getUser(params): Observable<any> {
    return this.http.get(`${environment.backendHost}/users/all`, {
      params,
    });
  }

  public register(params): Observable<any> {
    return this.http.post(`${environment.backendHost}/auth/register`, params);
  }

  public deleteUsers(params): Observable<any> {
    return this.http.post(`${environment.backendHost}/users/delete`, params);
  }

  public deleteGroups(params): Observable<any> {
    return this.http.post(`${environment.backendHost}/group/delete`, params);
  }

  public createGame(params): Observable<any> {
    return this.http.post(`${environment.backendHost}/game/create`, params);
  }

  public startGame(params): Observable<any> {
    return this.http.post(`${environment.backendHost}/game/start`, params);
  }

  public gamePlayerJoin(params): Observable<any> {
    return this.http.post(`${environment.backendHost}/game/join`, params);
  }

  public gameEventPlayerDiceResult(params): Observable<any> {
    return this.http.get(`${environment.backendHost}/game/events/player/move`, {
      params,
    });
  }

  public gameEventPlayerMove(params: {
    playerId: number;
    gameId: number;
    cellId: number;
  }): Observable<any> {
    return this.http.post(
      `${environment.backendHost}/game/events/player/move`,
      params
    );
  }

  public gameEventPlayerPath(params): Observable<any> {
    return this.http.get(`${environment.backendHost}/game/events/player/path`, {
      params,
    });
  }

  public gameEventNextTurn(params: { gameId: number }): Observable<any> {
    return this.http.post(
      `${environment.backendHost}/game/events/player/next-turn`,
      params
    );
  }

  public gameGetCells(): Observable<any> {
    return this.http.get(`${environment.backendHost}/game/cells`);
  }

  public endGame(params: { gameId: number }): Observable<any> {
    return this.http.post(
      `${environment.backendHost}/game/events/end-game`,
      params
    );
  }

  public deleteGame(params): Observable<any> {
    console.log(params);
    return this.http.post(`${environment.backendHost}/game/delete`, params);
  }

  public gameGetRandomQuestion(params): Observable<any> {
    return this.http.get(
      `${environment.backendHost}/game/events/player/question`,
      {
        params,
      }
    );
  }

  public gameAnserQuestion(params: {
    gameId;
    playerId;
    quizId;
    answersId;
  }): Observable<any> {
    return this.http.post(
      `${environment.backendHost}/game/events/player/answer`,
      params
    );
  }

  public putChips(params: { gameId; playerId; chipsId }): Observable<any> {
    return this.http.post(
      `${environment.backendHost}/game/events/player/putchips`,
      params
    );
  }

  public getMoreChips(params: { gameId; playerId; chips }): Observable<any> {
    return this.http.post(
      `${environment.backendHost}/game/events/player/morechips`,
      params
    );
  }

  public moveWithBike(params: { gameId; playerId }): Observable<any> {
    return this.http.post(
      `${environment.backendHost}/game/events/player/bike`,
      params
    );
  }

  public smash(params: { gameId; playerId }): Observable<any> {
    return this.http.post(
      `${environment.backendHost}/game/events/player/smash`,
      params
    );
  }

  public getRamdonChallenge(params) {
    return this.http.get(
      `${environment.backendHost}/game/events/player/challenge`,
      {
        params,
      }
    );
  }

  public setResultChallenge(params: { gameId; playerId; challengeId; passed }) {
    return this.http.post(
      `${environment.backendHost}/game/events/player/challenge-result`,
      params
    );
  }

  public downloadUserGroups(params): Observable<any> {
    return this.http.get(`${environment.backendHost}/group/export-users`, {
      params,
    });
  }

  public createUserRoles(params): Observable<any> {
    return this.http.get(`${environment.backendHost}/users/roles`, {
      params,
    });
  }

  public createGroup(params): Observable<any> {
    return this.http.post(
      `${environment.backendHost}/group/create`,
      params,
    );
  }


}
