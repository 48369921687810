import { SOCKET_EVENTS_CONFIG } from './../../config/socketEvents.config';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { Socket } from 'ngx-socket-io';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { GameStatusService } from 'src/app/services/game/game-status.service';
import { CELL_TYPES, CHALLENGE_TYPES, DEPOSIT_CELLS, GET_CHIP_CELLS } from 'src/config/global.config';
import { Answer } from '../entities/answer';
import { Cell } from '../entities/cell';
import { CellType } from '../entities/cell-type';
import { Challenge } from '../entities/challenge';
import { Chip } from '../entities/chip';
import { Game } from '../entities/game';
import { GameStatus } from '../entities/game-status';
import { PlayerStatus } from '../entities/player-status';
import { Question } from '../entities/question';
import { User } from '../entities/user';
import { ApiService } from './api/api.service';
import { ChallengeService } from './challenges/challenge.service';
import { GameService } from './game/game.service';
import { SessionService } from './login/session.service';
import { MessageService } from './message.service';
import { ModalService } from './modal/modal.service';

@Injectable({
  providedIn: 'root'
})
export class GameFacadeService {
  private subscriptions: Subscription = new Subscription();
  private _gameAvailable: Game = null;
  private _gameStatus: GameStatus = null;
  private lastDiceResult = 0;

  gameStart$ = this.socket.fromEvent(SOCKET_EVENTS_CONFIG.gameStart);
  gamePlayerJoin$ = this.socket.fromEvent(SOCKET_EVENTS_CONFIG.gamePlayerJoin);
  gameEventRollDice$ = this.socket.fromEvent(SOCKET_EVENTS_CONFIG.gameEventDiceRoll);
  gameEventDiceResult$ = this.socket.fromEvent(SOCKET_EVENTS_CONFIG.gameEventDiceResult);
  gameEventPlayerMovement$ = this.socket.fromEvent(SOCKET_EVENTS_CONFIG.gameEventPlayerMovement);
  gameEventNextTurn$ = this.socket.fromEvent(SOCKET_EVENTS_CONFIG.gameEventNextTurn);
  gameEventPlayerQuizAnswer$ = this.socket.fromEvent(SOCKET_EVENTS_CONFIG.gameEventPlayerQuizAnswer);
  gameEventPlayerQuiz$ = this.socket.fromEvent(SOCKET_EVENTS_CONFIG.gameEventPlayerQuiz);
  gameEventSelectedAnswers$ = this.socket.fromEvent(SOCKET_EVENTS_CONFIG.gameEventSelectedAnswers);
  gameEventPlayerGetChips$ = this.socket.fromEvent(SOCKET_EVENTS_CONFIG.gameEventPlayerQuizAnswer);
  gameEventPlayerOpenContainer$ = this.socket.fromEvent(SOCKET_EVENTS_CONFIG.gameEventPlayerOpenContainer);
  subscribeGameEventPlayerSelectChip$ = this.socket.fromEvent(SOCKET_EVENTS_CONFIG.gameEventPlayerSelectChip);
  subscribeGameEventPlayerBike$ = this.socket.fromEvent(SOCKET_EVENTS_CONFIG.gameEventPlayerBike);
  subscribeGameEventPlayerDepositEnd$ = this.socket.fromEvent(SOCKET_EVENTS_CONFIG.gameEventPlayerDepositEnd);

  cells$: BehaviorSubject<Cell[]> = new BehaviorSubject<Cell[]>(null);
  gameAvailable$: BehaviorSubject<Game> = new BehaviorSubject<Game>(this._gameAvailable);
  gameStatus$: BehaviorSubject<GameStatus> = new BehaviorSubject<GameStatus>(this._gameStatus);
  gameStarted$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  availablePath$:BehaviorSubject<number[]> = new BehaviorSubject<number[]>([]);
  movePlayerToCell$:Subject<{playerId: number, path: Cell[]}> = new Subject<{playerId: number, path: Cell[]}>();
  question$:BehaviorSubject<Question> = new BehaviorSubject<Question>(null);
  selectedAnswers$: Subject<Answer[]> = new Subject<Answer[]>();
  deposit$: Subject<CellType> = new Subject<CellType>();
  selectedChipsToDeposit$: Subject<Chip[]> = new Subject<Chip[]>();
  getMoreChips$: Subject<{quantity, otherPlayers}> = new Subject<{quantity, otherPlayers}>();
  diceResult$: Subject<number> = new Subject<number>();
  gameEventPlayerBike$: Subject<{playerId:number}> = new Subject<{playerId: number}>();
  gameEventPlayerDepositEnd$: Subject<{gameId: number, points: number, chips: number}> = new Subject<{gameId: number, points: number, chips: number}>();


  constructor(
    private gameStatusService: GameStatusService,
    private gameService: GameService,
    private sessionService: SessionService,
    private socket: Socket,
    private apiService: ApiService,
    public _snackBar: MatSnackBar,
    private modalService: ModalService,
    private router: Router,
    private messageService: MessageService,
    private challengeService: ChallengeService
  ) {
    this.subscribeGameEvents();
  }

  createGame(users: User[]) {
    const players = users.map(user => user.player_id);
    const params = {players, user: this.sessionService.getUserId()};

    this.gameService.createGame(params).subscribe(
      (game: Game) => {
        this.gameAvailable$.next(game);
        this.socket.emit(SOCKET_EVENTS_CONFIG.createGame, {users: players, game })
      },
      err => {console.error(err)}
    )
  }

  endGame(gameId: number) {
    const params = {game: gameId};

    this.gameService.endGame(params).subscribe(
      (game: Game) => {
        //this.socket.emit(SOCKET_EVENTS_CONFIG.gameEventEndGame, { game })
      },
      err => {console.error(err)}
    )
  }

  deleteGame(gameId: number) {
    const params = {gameId: gameId};

    this.gameService.deleteGame(params).subscribe(
      (game: Game) => {
        //this.socket.emit(SOCKET_EVENTS_CONFIG.gameEventEndGame, { game })

      },
      err => {console.error(err)}
    )
  }

  onCreatedGame(game: Game) {
    this.gameStatusService.getGameStatus(game.game_status_id).subscribe(
      (gameStatus: GameStatus) => {
        this._gameStatus = gameStatus;
        this.gameStatus$.next(this._gameStatus);
        this.gameAvailable$.next(gameStatus.game);
      },
      err => console.log(err)
    )
  }

  onGamePlayerJoin() {
    if(!this.gameAvailable$.getValue()) this.router.navigate(['/home']);
    const params = {
      playerId: this.sessionService.getUserId(),
      gameId: this.gameAvailable$.getValue().id
    }

    this.gameStatusService.gamePlayerJoin(params).subscribe(
      gameStatus => {
        this._gameStatus = gameStatus;
        this.gameStatus$.next(this._gameStatus);
        this.socket.emit(SOCKET_EVENTS_CONFIG.gamePlayerJoin, {gameId: this.gameAvailable$.getValue().id, playerId: this.sessionService.getUserId()});
        if(this.gameStatusService.areAllPlayersConnected(this.gameStatus$.getValue())
          && !this.gameStatusService.isGameStarted(this.gameStatus$.getValue())) {
            this.gameStart();
        }

      }
    )
  }

  gameEventRollDice(diceResult) {
    this.gameService.gameEventPlayerDiceResult({gameId: this.gameStatus$.getValue().game.id, dice:diceResult, playerId: this.sessionService.getUserId()})
    .subscribe(
      availablePath => {
        this.lastDiceResult = diceResult;
        this.availablePath$.next(availablePath);
        this.socket.emit(SOCKET_EVENTS_CONFIG.gameEventDiceResult, {gameId: this.gameAvailable$.getValue().id, dice: diceResult});
      }
    )
  }

  gameEventPlayerMove(cellId){
    let path;
    this.gameService.gameEventPlayerPath({gameId: this.gameStatus$.getValue().game.id, cellId, playerId: this.sessionService.getUserId(), dice: this.lastDiceResult})
    .subscribe(
      movementPath => { path = movementPath;
      this.gameService.gameEventPlayerMove({gameId: this.gameStatus$.getValue().game.id, cellId, playerId: this.sessionService.getUserId()})
      .subscribe(
        () => {
          this.socket.emit(SOCKET_EVENTS_CONFIG.gameEventPlayerMovement, {gameId: this.gameAvailable$.getValue().id,
            playerId: this.sessionService.getUserId(),
            path
          })
          this.checkCellType(this.getCurrentCell(cellId));
        }
      )}
    );
  }

  gameEventNextTurn() {
    this.beforeNextTurn();
    this.gameService.gameEventNextTurn({gameId: this.gameAvailable$.getValue().id}).subscribe(
      () => {},
      () => {},
      () => {
        this.socket.emit(SOCKET_EVENTS_CONFIG.gameEventNextTurn, {gameId: this.gameAvailable$.getValue().id})
      }
    )
  }

  beforeNextTurn() {
    this.smash();
  }

  private showPlayerTurnMessage(gameStatus: GameStatus) {
    const playerTurn = gameStatus.player_turn;
    const gameStarted = gameStatus.started_at != null;
    this.messageService.reset();

    /*if( playerTurn !== this.sessionService.getUserId()){
      if(playerTurn == 0) this.messageService.setMessage("Esperando a los jugadores")
      else {
        this.messageService.setMessage(`Turno del jugador ${playerTurn}`)

      }
    } else {
      this.messageService.setMessage(`Es tu turno`)
    }*/
    if (!gameStarted) {
      this.messageService.setMessage("Esperando a los jugadores")
    } else {
      if (playerTurn == this.sessionService.getUserId()) {
        this.messageService.setMessage(`Es tu turno`)
      } else {
        this.messageService.setMessage(`Turno del jugador ${playerTurn}`)
      }
    }
    this.messageService.show();
  }

  private smash() {
    let msg = '';

    this.gameService.smash(this.gameAvailable$.getValue().id, this.sessionService.getUserId()).subscribe(
      (playersInCell: PlayerStatus[]) => {
        if(playersInCell.length > 1) {
          msg = 'Has aplastado a varios jugadores, pierden un turno y se van a la casilla basura más cercana'
        }

        if(playersInCell.length == 1) {
          msg = 'Has aplastado a un jugador, pierde un turno y se va a la casilla basura más cercana'
        }

        if(playersInCell.length > 0) {
          this.modalService.show('success', msg);
        }

      }
    )
  }

  getGameAvailable(): Game {
    return this.gameAvailable$.getValue();
  }

  getGameStatus(): GameStatus {
    return this._gameStatus;
  }

  isGameStarted(): boolean {
    return this._gameStatus.started_at != null;
  }

  isPlayerTurn(): boolean {
    // if(!this.gameStatus$.getValue()) return;
    console.log(`is player turn: ${this.gameStatus$.getValue().player_turn} : ${this.getMyPlayerStatus().player_order}`)
    return this.gameService.isPlayerTurn(this.gameStatus$.getValue(), this.getMyPlayerStatus().player_order);
  }

  getMyPlayerStatus(): PlayerStatus {
    if(!this.gameStatus$.getValue().players_status) return;
    return this.gameStatus$.getValue().players_status.filter(
      (player: PlayerStatus) => player.player_id === this.sessionService.getUserId()
    )[0];
  }

  getCurrentGame(checkRejoin: boolean = false) {
    if(this.gameAvailable$.getValue() && this.gameStatus$.getValue()) return;

    this.gameStatusService.getCurrentGame({playerId: this.sessionService.getUserId()}).subscribe(
      (gameStatus: GameStatus) => {
        this.gameStatus$.next(gameStatus);
        this.gameAvailable$.next(gameStatus.game);
        if(checkRejoin) this.checkRejoin();
      }
    )
  }

  getGameCells() {
    this.gameService.gameGetCells().subscribe(
      (cells: Cell[]) => this.cells$.next(cells)
    )
  }

  getRandomQuestion() {
    this.gameService.getRandomQuestion({gameId: this.gameStatus$.getValue().game.id}).subscribe(
      (question: Question) => {
        this.question$.next(question);
        this.socket.emit(SOCKET_EVENTS_CONFIG.gameEventPlayerQuiz, {gameId: this.gameStatus$.getValue().game.id, question})
      },
      (e) => console.error(e)
    )
  }

  gameEventSelectedAnswers(selectedAnswers: Answer[]) {
    this.socket.emit(SOCKET_EVENTS_CONFIG.gameEventSelectedAnswers, {gameId: this.getGameStatus().game.id, selectedAnswers})
  }

  gameAnswerQuestion(question: {quiz: Question, answers: Answer[]}) {
    const params = {
      playerId: this.sessionService.getUserId(),
      gameId: this.gameStatus$.getValue().game.id,
      quizId: question.quiz.id,
      answersId: question.answers.map(answer => answer.id)
    }

    let response = true;

    const answersId = question.answers.map(answer => answer.id);
    const correctAnswersId = question.quiz.correct_answers.map(answer => answer.id);
    answersId.forEach(answerId => {
      if(!correctAnswersId.includes(answerId)) {
        response = false;
        return;
      }
    });

    this.gameService.answerQuestion(params).subscribe(
      (data : {isCorrect, isPenalisation, playerStatus}) => {
        this.socket.emit(SOCKET_EVENTS_CONFIG.gameEventPlayerQuizAnswer,
          {gameId: params.gameId, isCorrect: data.isCorrect, isPenalisation: data.isPenalisation, playerOrder: this.getMyPlayerStatus().player_order
        })
      }
    )
    return response;
  }

  private getCurrentCell(cellId: number): Cell {
    const cell = this.cells$.getValue().find(
      (cell: Cell) => cell.id ===  cellId
    )
    return cell;
  }

  private checkCellType(cell: Cell) {
    if(cell.id == 1) {this.runEndGameFlow(); return;}
    if(cell.types[0].name === CELL_TYPES.question) {this.runQuestionFlow(); return;}
    if(cell.types[0].name === CELL_TYPES.default) {this.runDefaultFlow(); return;}
    if(cell.types[0].name === CELL_TYPES.bicycle) {this.runBikeFlow(); return;}
    if(cell.types[0].name === CELL_TYPES.trash) {this.runTrashFlow(cell); return;}
    if(DEPOSIT_CELLS.includes(cell.types[0].name)) {this.runDepositFlow(cell); return;}
    if(GET_CHIP_CELLS.includes(cell.types[0].name)) {this.runGetChipsFlow(cell); return;}
    if(cell.types[0].name === CELL_TYPES.challenge) {this.runChallengeFlow(); return;}
    this.runDefaultFlow(); return;
  }

  private runChallengeFlow() {
    this.getRamdonChallenge();
  }

  private runQuestionFlow() {
    this.getRandomQuestion();
  }

  private runDefaultFlow() {
    this.gameEventNextTurn();
  }

  private runBikeFlow() {
    this.gameService.moveWithBike(this.gameAvailable$.getValue().id, this.sessionService.getUserId()).subscribe(
      () => {
        this.modalService.show('success', 'Te vas a la otra casilla de bicicleta');
        this.socket.emit(SOCKET_EVENTS_CONFIG.gameEventPlayerBike,{gameId: this.gameAvailable$.getValue().id, playerId: this.sessionService.getUserId()});
        this.gameEventNextTurn();
      }
    )
  }

  private runTrashFlow(cell: Cell) {
    this.modalService.show('fail', `Pierdes ${cell.lost_turns} turno intentando levantar el contenedor`);
    this.gameEventNextTurn();
  }

  private runGetChipsFlow(cell: Cell) {
    if(cell.types[0].name === CELL_TYPES.city_hall) {this.playersDrawChips(1, true); return;}
    if(cell.types[0].name !== CELL_TYPES.city_hall) {this.getMoreChips(cell); return;}
  }

  private runDepositFlow(cell: Cell) {
    const cellType = cell.types[0];
    this.deposit$.next(cellType);
  }

  private runEndGameFlow() {
    const userOrder = this.getMyPlayerStatus().player_order;

    if(this.gameStatus$.getValue().players_status[userOrder].chips.length > 0) return;

    this.gameService.gameEndGame({gameId: this.gameStatus$.getValue().game.id});
  }

  depositContainerEvent(type, quantity) {
    const data = {
      gameId: this.gameAvailable$.getValue().id,
      type,
      quantity
    };

    this.socket.emit(SOCKET_EVENTS_CONFIG.gameEventPlayerOpenContainer, data);
  }

  depositChipEvent(chips: Chip[]) {
    const data = {
      gameId: this.gameAvailable$.getValue().id,
      chips
    }

    this.socket.emit(SOCKET_EVENTS_CONFIG.gameEventPlayerSelectChip, data);
  }

  private getRamdonChallenge() {
    this.apiService.getRamdonChallenge({gameId: this.gameAvailable$.getValue().id}).subscribe(
      (challenge: Challenge) => {
        this.challengeService.challenge$.next(challenge);
        if(challenge.type.name === CHALLENGE_TYPES.penalisation) {
          this.challengeService.setChallengeResult(
            {
              gameId: this.gameAvailable$.getValue().id,
              playerId: this.sessionService.getUserId(),
              challengeId: challenge.id,
              passed: false
            }
          ).subscribe(
            result => {
              // todo: emit socket challenge penalisation event
              this.gameEventNextTurn();
            }
          )
        }
      }
    )
  }

  private playersDrawChips(quantity: number, otherPlayers: boolean) {
    this.getMoreChips$.next({quantity, otherPlayers});
    let players = [];

    if(otherPlayers === true) {
      players = this.gameStatus$.getValue().players_status.filter(playerStatus => playerStatus.player_id != this.sessionService.getUserId());
      this.modalService.show(
        'success', `El resto recibe ${quantity} fichas de residuo`
      );
    }

    if(otherPlayers === false) {
      this.modalService.show(
        'fail', `Todos recibís ${quantity} fichas residuo`
      );
      players = this.gameStatus$.getValue().players_status;
    }

    const params = {
      gameId: this.gameAvailable$.getValue().id,
      playerId: null,
      chips: quantity
    }

    players.forEach((playerStatus: PlayerStatus) => {
      params.playerId= playerStatus.player_id;
      this.apiService.getMoreChips(params).subscribe();
    });

    this.socket.emit(SOCKET_EVENTS_CONFIG.gameEventPlayerGetChip,
      {gameId: params.gameId, usersId: players, chips: params.chips });

    this.gameEventNextTurn();
  }

  private getMoreChips(cell: Cell) {
    const params = {
      gameId: this.gameAvailable$.getValue().id,
      playerId: this.sessionService.getUserId(),
      chips: cell['penalisationChips'] || 3
    }

    this.apiService.getMoreChips(params).subscribe(
      () => {
            this.gameEventNextTurn();
            if(cell.lost_turns != 0) this._snackBar.open('fail', `¡Pierdes ${cell.lost_turns} turnos!`);
            this.modalService.show('fail', `Recibes ${params.chips} residuo`);
            this.socket.emit(SOCKET_EVENTS_CONFIG.gameEventPlayerGetChip,
              {gameId: params.gameId, usersId: [this.getMyPlayerStatus().player_id], chips: params.chips });
          }
    )
  }

  private gameEventPlayerGetChips(data: {gameId: number, usersId: number[], chips: number}) {
    const playersStatus = this.gameStatus$.getValue().players_status.filter(
      playerStatus => {data.usersId.includes(playerStatus.player_id)}
    )
    let playersName;
    let msg = '';

    if(data.usersId.length > 1){
      playersStatus.forEach(playerStatus => {
        playersName += `${playerStatus.player.id}, `
      });

      msg = `Los jugadores ${playersName} reciben ${data.chips} fichas.`;
    } else {
      playersName = playersStatus[0].player.user.name;
      msg = `${playersName} recibe ${data.chips} fichas.`
    }

    this.modalService.show('fail', msg);

  }

  private checkRejoin() {
    this.socket.emit(SOCKET_EVENTS_CONFIG.playerRejoinGame,
      {
        userId: this.sessionService.getUserId(),
        gameId: this.gameAvailable$.getValue().id
      }
    );
  }

  private onGameStatusChange() {
    if(!this.gameStatus$.getValue()) return;
    this.gameStatusService.getGameStatus(this.gameStatus$.getValue().id).subscribe(
      (gameStatus: GameStatus) => {
        console.log("on game status changeee: "+gameStatus.player_turn);
        this.gameStatus$.next(gameStatus);
        this._gameStatus = gameStatus;
        this.showPlayerTurnMessage(gameStatus);
      }
    )

  }

  private gameStart() {
    this.gameStatusService.startGame({gameId: this.gameAvailable$.getValue().id}).subscribe(
      gameStatus => {
        this.gameStatus$.next(gameStatus);
        this._gameStatus = gameStatus;
        this.gameStarted$.next(true);
        this.socket.emit(SOCKET_EVENTS_CONFIG.gameStart, {gameId: this.gameAvailable$.getValue().id});
      },
      () => this.gameStarted$.next(false)
    )
  }

  private showQuestion(question: Question) {
    if(this.isPlayerTurn()) return;
    this.question$.next(question);
  }

  private showSelectedAnswers(answers: Answer[]) {
    if(this.isPlayerTurn()) return;
    this.selectedAnswers$.next(answers);
  }

  private subscribeQuestion() {
    this.subscriptions.add(
      this.gameEventPlayerQuiz$.subscribe(
        (data: {question: Question}) => {
          this.showQuestion(data.question);
        }
      )
    )
  }
  private subscribeSelectedAnswers() {
    this.subscriptions.add(
      this.gameEventSelectedAnswers$.subscribe(
        (data: {gameId:number, selectedAnswers: Answer[]}) => {
          this.showSelectedAnswers(data.selectedAnswers);
        }
      )
    )
  }

  private subscribeQuestionAnswer() {
    this.subscriptions.add(
      this.gameEventPlayerQuizAnswer$.subscribe(
        (data: {gameId, isCorrect, isPenalisation, playerOrder}) => {
          console.log(data.playerOrder, this.getMyPlayerStatus().player_order)
          if(data.playerOrder === this.getMyPlayerStatus().player_order) return;
          const question = this.question$.getValue();

          this.question$.next(null);
          this.selectedAnswers$.next(null);

          if(data.isPenalisation) {
            const msg = question.penalisation_chips > 0 ? `Recibes ${question.penalisation_chips} fichas residuo.` : `Pierdes ${question.lost_turns} turnos.`
            this.modalService.show('fail', msg)

            return;
          }

          const msg = data.isCorrect ? `Jugador ${data.playerOrder}` + ' acierta y recibe 5 puntos' : ' ha fallado la pregunta y recibe 1 residuo';
          const type = data.isCorrect ? 'success' : 'fail';

          this.modalService.show(type, msg);
        }
      )
    )
}



  private subscribeGamePlayerJoin() {
    this.subscriptions.add(
      this.gamePlayerJoin$.subscribe(
        (data: {playerId, gameId}) => {
          this.onGameStatusChange();
        }
      )
    )
  }

  private subscribeGameEventDiceResult() {
    this.subscriptions.add(
      this.gameEventDiceResult$.subscribe(
        (data: {gameId, dice}) => {
          this.diceResult$.next(data.dice)
        }
      )
    )
  }

  private subscribeGameEventPlayerMovement() {
    this.subscriptions.add(
      this.gameEventPlayerMovement$.subscribe(
        (data: {playerId, path}) => {
          this.movePlayerToCell$.next({playerId: data.playerId, path: data.path});
        }
      )
    )
  }

  private subscribeOnGameEventNextTurn() {
    this.subscriptions.add(
      this.gameEventNextTurn$.subscribe(
        () => {
          this.onGameStatusChange();
        }
      )
    )
  }

  private subscribeGameEventPlayerOpenContainer() {
    this.subscriptions.add(
      this.gameEventPlayerOpenContainer$.subscribe(
        (data : {gameId, type, chips, quantity}) => {
          if(this.isPlayerTurn() == true) return;
          this.modalService.show("deposit", "El jugador esta depositando fichas")
        }
      )
    )

  }
  private subscribeGameEventPlayerSelectChip() {
    this.subscriptions.add(
      this.subscribeGameEventPlayerSelectChip$.subscribe(
        (data : {gameId, chips: Chip[]}) => {
          if(this.isPlayerTurn() == false) return;
          this.selectedChipsToDeposit$.next(data.chips);

        }
      )
    )
  }

  private subscribeGameEventPlayerGetChips() {
    this.subscriptions.add(
      this.gameEventPlayerGetChips$.subscribe(
        (data: {gameId: number, usersId: number[], chips: number}) => {
          this.gameEventPlayerGetChips(data);
        }
      )
    )
  }

  private subscribeOnGameStart() {
    this.subscriptions.add(
      this.gameStart$.subscribe(
        () => {
          this.onGameStatusChange();
        }
      )
    )
  }

  private subscribeGameEventPlayerBike() {
    this.subscriptions.add(
      this.gameEventPlayerBike$.subscribe(
        (data: {playerId: number}) => {
          if(data.playerId === this.sessionService.getUserId()) return;
          this.modalService.show('success', `El jugador ${data.playerId} se ha ido a la otra casilla de bicicleta`);
        }
      )
    )
  }

  emitDepositEnd(points: number, chips: number) {
    this.socket.emit(SOCKET_EVENTS_CONFIG.gameEventPlayerDepositEnd,{gameId: this.gameAvailable$.getValue().id, points, chips})
  }

  private subscribeGameEventPlayerDepositEnd() {
    this.subscriptions.add(
      this.subscribeGameEventPlayerDepositEnd$.subscribe(
        (data: {gameId: number, points: number, chips: number}) => {
          // this.modalService.show('success', `El jugador ${data.playerId} se ha ido a la otra casilla de bicicleta`);
          if(this.isPlayerTurn() == false) {
            if(data.points != 0) {
              this.modalService.show('fail', `El jugador ha perdido ${data.points} por depositar mal ${data.chips} residuos`);
            } else {
              this.modalService.show('success', `El jugador ha depositado correctamente sus residuos`);
            }
          }
        }
      )
    )
    this.deposit$.next(null);
  }

  private subscribeGameEvents() {
    this.subscribeGamePlayerJoin();
    this.subscribeOnGameStart();
    this.subscribeGameEventDiceResult();
    this.subscribeGameEventPlayerMovement();
    this.subscribeOnGameEventNextTurn();
    this.subscribeQuestionAnswer();
    this.subscribeGameEventPlayerGetChips();
    this.subscribeQuestion();
    this.subscribeSelectedAnswers();
    this.subscribeGameEventPlayerOpenContainer();
    this.subscribeGameEventPlayerSelectChip();
    this.subscribeGameEventPlayerBike();
    this.subscribeGameEventPlayerDepositEnd();
  }

}
