import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AudioService } from '../audio.service';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  modal: BehaviorSubject<{type, active, message}> = new BehaviorSubject<{type, active, message}>({type: '', active: false, message: ''});

  constructor(
    private audioService: AudioService
  ) { }

  show(type: string, message: string) {
    this.modal.next(
      {active: true, type, message}
    )
    this.audioService.playNotificationSound(type);
  }

  hide() {
    this.modal.next(
      {active: false, type: null, message: null}
    )
  }
}
