import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Socket } from 'ngx-socket-io';
import { Subject } from 'rxjs';
import { SOCKET_EVENTS_CONFIG } from 'src/config/socketEvents.config';
import { GameFacadeService } from './game-facade.service';
import { LoginService } from './login/login.service';
import { SessionService } from './login/session.service';

@Injectable({
  providedIn: 'root'
})
export class LoginFacadeService {
  onLoginError$: Subject<boolean> = new Subject();

  constructor(
    private loginService: LoginService,
    private sessionService: SessionService,
    private gameFacadeService: GameFacadeService,
    private router: Router,
    private socket: Socket
  ) { }

  doLogin(params) {
    this.loginService.login(params).subscribe(
      (response) => {
        const session = this.sessionService.setSession(response);
        this.socket.emit(SOCKET_EVENTS_CONFIG.userLogin, {userId: this.sessionService.getUserId()});
        this.onLoginError$.next(false);
        (session) ? this.router.navigate(['/home']) : false;
      },
      () => {
        this.onLoginError$.next(true);
      }
    );
  }

  isLogged() {
    if(this.sessionService.getSession().getValue() === null) {
      this.sessionService.getSessionFromStorage();
    }

    return this.sessionService.isSessionValid();
  }

}
