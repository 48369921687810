import { Injectable } from '@angular/core';
import { GameStatus } from 'src/app/entities/game-status';
import { ApiService } from '../api/api.service';


@Injectable({
  providedIn: 'root'
})

export class GameStatusService {

  constructor(
    private apiService: ApiService
  ) {
  }

  existsGameStatus(id: number): boolean {
    return true;
  }

  getGameStatus(gameStatusId) {
    const params = {gameStatusId};
    return this.apiService.getGameStatus(params);
  }

  getCurrentGame(params: {playerId}) {
    return this.apiService.getCurrentGame(params);
  }

  gamePlayerJoin(params) {
    return this.apiService.gamePlayerJoin(params);
  }

  startGame(params) {
    return this.apiService.startGame(params);
  }

  isGameStarted(gameStatus: GameStatus) {
    return gameStatus.started_at ? true : false;
  }

  isGameOver(gameStatus: GameStatus) {
    return gameStatus.finished ? true : false;
  }

  areAllPlayersConnected(gameStatus: GameStatus) {
    var areAllPlayersConnected = true;
    gameStatus.players_status.forEach(
      playerStatus => {
        if(!playerStatus.active) { areAllPlayersConnected = false; return};
      }
    )
    return areAllPlayersConnected;
  }
}
