import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AudioService {

  status : boolean = true;

  mainMusic = new Audio('/assets/home/mainmenu.mp3');
  gameMusic = new Audio('/assets/game/gamesound.mp3');

  buttonSound = new Audio("/assets/buttonsound.mp3");

  notificationOkSound = new Audio("/assets/game/notification_ok.mp3");
  notificationWrongSound = new Audio("/assets/game/notification_wrong.mp3");

  constructor() { }

  public initMainMusic() {
    this.mainMusic.loop = true;
    this.mainMusic.play();
  }

  public initGameMusic() {
    this.status = true;
    this.gameMusic.loop = true;
    this.gameMusic.play();
  }

  public pauseGameMusic() {
    this.status = false;
    this.gameMusic.pause();
  }

  public playButtonSound() {
    if (this.status) {
      this.buttonSound.play();
    }
  }

  public playNotificationSound(type: string) {
    if (type == 'fail') {
      this.notificationWrongSound.play();
    } else {
      this.notificationOkSound.play();
    }
  }


}


