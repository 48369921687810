import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { Socket } from 'ngx-socket-io';
import { Subscription } from 'rxjs';
import { SOCKET_EVENTS_CONFIG } from 'src/config/socketEvents.config';
import { Game } from '../entities/game';
import { GameFacadeService } from './game-facade.service';

@Injectable({
  providedIn: 'root'
})
export class SocketService {
  onCreatedGame$ = this.socket.fromEvent(SOCKET_EVENTS_CONFIG.createGame);

  private subscriptions: Subscription = new Subscription();

  constructor(
    private socket: Socket,
    private gameFacadeService: GameFacadeService,
    public _snackBar: MatSnackBar,
  ) {
    this.subscribeAdminEvents();
  }

  private subscribeAdminEvents() {
    this.subscriptions.add(
      this.subscribeOnCreatedGame()
    );
  }

  showNotification(message, action) {
    this._snackBar.open(
      message, action,
      {
        horizontalPosition: 'center',
        verticalPosition: 'top',
      }
    )
  }

  private subscribeOnCreatedGame() {
    this.onCreatedGame$.subscribe(
      (data: {users, game:Game}) => {
        this.gameFacadeService.onCreatedGame(data.game);
        this.showNotification(`Puedes unirte a la partida`, 'Cerrar');
      }
    )
  }


}
