import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CanActivateIsLogged } from './guards/canActivateIsLogged.service';
import { CanActivateIsNotLoggedService } from './guards/canActivateIsNotLoggedService.service';
import { CanActivateExistsGame } from './guards/exists-game.service';

const routes: Routes = [
  {
    path: 'game',
    canActivate: [CanActivateIsLogged],
    loadChildren: () => import('./game/game.module').then(m => m.GameModule)
  },
  {
    path: 'home',
    canActivate: [CanActivateIsLogged],
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule)
  },
  {
    path: 'login',
    canActivate: [CanActivateIsNotLoggedService],
    loadChildren: () => import('./login/login.module').then(m => m.LoginModule)
  },
  {
    path: 'admin',
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule)
  },
  {
    path: '', pathMatch:'full', redirectTo: 'home'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  providers: [CanActivateIsLogged],
  exports: [RouterModule]
})
export class AppRoutingModule { }
