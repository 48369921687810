import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GameStatus } from 'src/app/entities/game-status';
import { Player } from 'src/app/entities/player';
import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root'
})
export class GameService {

  constructor(
    private apiService: ApiService,
  ) { }

  createGame(params): Observable<any> {
    return this.apiService.createGame(params);
  }

  gameEventPlayerDiceResult(params: {playerId, gameId, dice}) {
    return this.apiService.gameEventPlayerDiceResult(params);
  }

  gameEventPlayerMove(params: {playerId: number, gameId:number, cellId:number}) {
    return this.apiService.gameEventPlayerMove(params);
  }

  gameEventPlayerPath(params: {playerId: number, gameId:number, cellId:number, dice:number}) {
    return this.apiService.gameEventPlayerPath(params);
  }

  gameEventNextTurn(params: {gameId: number}) {
    return this.apiService.gameEventNextTurn(params);
  }

  endGame(params): Observable<any> {
    return this.apiService.endGame(params);
  }

  deleteGame(params): Observable<any> {
    return this.apiService.deleteGame(params);
  }

  gameGetCells() {
    return this.apiService.gameGetCells();
  }

  gameEndGame(params: {gameId: number}) {
    return this.apiService.endGame(params);
  }

  getRandomQuestion(params: {gameId: number}) {
    return this.apiService.gameGetRandomQuestion(params);
  }

  answerQuestion(params: {gameId, playerId, quizId, answersId}) {
    return this.apiService.gameAnserQuestion(params);
  }

  isPlayerTurn(gameStatus: GameStatus, userId) {
    return gameStatus.player_turn === userId ? true : false;
  }

  getPlayerOnTurn(gameStatus: GameStatus): Player {
    const player =  gameStatus.players_status.filter(
      player => player.player_order == gameStatus.player_turn
    );

    if(!player || player.length == 0) return null;
    return player[0].player;
  }

  moveWithBike(gameId, playerId) {
    const params = {
      gameId,
      playerId
    }
    return this.apiService.moveWithBike(params);
  }

  smash(gameId, playerId) {
    const params = {
      gameId,
      playerId
    }
    return this.apiService.smash(params);
  }

}
