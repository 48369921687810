export const SOCKET_EVENTS_CONFIG = {
  createGame: 'admin-create-game',
  gameStart: 'game-start',
  gamePlayerJoin: 'game-player-join',
  playerRejoinGame: 'player-rejoin-game',
  userLogin: 'user-login',
  gameEventDiceResult: 'game-event-dice-result',
  gameEventDiceRoll: 'game-event-roll-dice',
  gameEventPlayerMovement: 'game-event-player-movement',
  gameEventNextTurn: 'game-event-next-turn',
  gameEventPlayerQuiz: 'game-event-player-quiz',
  gameEventSelectedAnswers: 'game-event-selected-answers',
  gameEventPlayerQuizAnswer: 'game-event-player-quiz-answer',
  gameEventPlayerGetChip: 'game-event-player-get-chip',
  gameEventPlayerOpenContainer: 'game-event-player-open-container',
  gameEventPlayerSelectChip: 'game-event-player-select-chip',
  gameEventPlayerChallengeResult: 'game-event-player-challenge-result',
  gameEventPlayerChallenge: 'game-event-player-challenge',
  gameEventPlayerChallengeSoupChars: 'game-event-player-soup-chars',
  gameEventPlayerChallengeSoupSelectedChars: 'game-event-player-soup-selected-chars',
  gameEventPlayerChallengeSoupCharsDone: 'game-event-player-chars-word-done',
  gameEventPlayerChallengeDifferences: 'game-event-player-challenge-differences',
  gameEventPlayerChallengeResultDifferences: 'game-event-player-challenge-result-differences',
  gameEventPlayerChallengeSelectDifference: 'game-event-player-challenge-select-difference',
  gameEventPlayerChallengeHangman: 'game-event-player-challenge-hangman',
  gameEventPlayerChallengeResultHangman: 'game-event-player-challenge-result-hangman',
  gameEventPlayerChallengeHangmanSelectChar: 'game-event-player-challenge-hangman-select-char',
  gameEventPlayerChallengeHangmanNextWord: 'game-event-player-challenge-hangman-next-word',
  gameEventPlayerBike: 'game-event-player-bike',
  gameEventPlayerDepositEnd: 'game-event-player-deposit-end',
  gameEventEndGame: 'game-event-end-game'

}
