import { Component } from '@angular/core';
import { AudioService } from './services/audio.service';
import { SocketService } from './services/socket.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'frontend';
  constructor(
    private socketService: SocketService,
  ) {

  }
}

