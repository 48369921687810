import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LoginFacadeService } from '../services/login-facade.service';

@Injectable({
  providedIn: 'root'
})
export class CanActivateIsNotLoggedService {

  constructor(private loginFacadeService: LoginFacadeService, private router: Router) { }

  canActivate() {
      if (this.loginFacadeService.isLogged()) {
          this.router.navigate(['/home']);
          return false;
      }

      return true;
  }
}
