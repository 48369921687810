import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CanActivate } from '@angular/router';
import { LoginFacadeService } from '../services/login-facade.service';

@Injectable()
export class CanActivateIsLogged implements CanActivate {

    constructor(private loginFacadeService: LoginFacadeService, private router: Router) { }

    canActivate() {
        if (!this.loginFacadeService.isLogged()) {
            this.router.navigate(['/login']);
            return false;
        }

        return true;
    }
}
