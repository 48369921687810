import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Challenge } from 'src/app/entities/challenge';
import { ApiService } from '../api/api.service';
@Injectable({
  providedIn: 'root'
})
export class ChallengeService {
  challenge$:Subject<Challenge> = new Subject<Challenge>();

  constructor(
    private apiService: ApiService
  ) { }

  setChallengeResult(params: {gameId:number, playerId:number, challengeId: number, passed: boolean}) {
    return this.apiService.setResultChallenge(params);
  }

  startChallenge(challenge: Challenge) {

  }
}
